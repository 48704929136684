/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(../fonts/Montserrat-Medium500ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(../fonts/Montserrat-Medium500.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(../fonts/Montserrat-SemiBold600ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(../fonts/Montserrat-SemiBold600.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(../fonts/Montserrat-Bold700ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(../fonts/Montserrat-Bold700.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url(../fonts/Montserrat-ExtraBold800ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url(../fonts/Montserrat-ExtraBold800.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProExt.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansPro.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProGreekExt.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProGreekExt.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProVietnamese.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProLatinExt.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/SourceSansProLatin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProExt400.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansPro400.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProGreekExt400.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProGreek400.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProVietnamese400.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProLatinExt400.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSansProLatin400.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProExt600.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansPro600.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProGreekExt600.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProGreek600.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProVietnamese600.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProLatinExt600.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSansProLatin600.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* nunito-sans-200 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-600 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-800 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* nunito-sans-900 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-sans-v15-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-100 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-200 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-300 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-regular - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-600 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-700 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-800 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* poppins-900 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v20-devanagari_latin_latin-ext-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


/*# sourceMappingURL=enginestyle.css.map */
html {
  scroll-behavior: smooth;
}

h1 {
  font-weight: 700 !important;
  font-family: Nunito Sans !important;
  font-size: 26px !important;
  line-height: 30px;
  color: #455A64 !important;
}

h2 {
  font-weight: 700 !important;
  font-family: Nunito Sans !important;
  color: #455a64;
}

h3 {
  font-weight: 700 !important;
  font-family: Nunito Sans !important;
  color: #455a64;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #67757c;
  background-color: #FFFFFF;
}

.border-bottom {
  border-bottom: 2px solid #0F3356 !important;
}

.card {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  background-color: #fff;
  border: 0;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  z-index: 1;

  &:hover {
    -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
    -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  }
}

.card-login {
  width: 384px;
}

.card-login,
.card-register {
  .card-logo-top {
    background-color: #0F3356;
    padding: 1em 0;
  }

  .h3 {
    margin-top: 0.6em;
    font-family: 'Montserrat', sans-serif;

    &::after {
      content: "";
      min-height: 4px;
      min-width: 30%;
      margin: 0.3em 35% 0.5em 35%;
      background-color: #8FD4F2;
      display: block;
    }
  }

  a {
    color: gray;
  }
}

.bg-dark {
  background-color: #0F3356 !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .main-sidebar {
    margin-left: 0 !important;
  }
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.card-body {
  padding: 1.25rem;
}

a:hover {
  text-decoration: none !important;
}

.dropdown-item:hover {
  text-decoration: none !important;
}

.header-logo-link-sidebar {
  width: 56px;
}

.header-logo-sidebar {
  width: 40px;
  overflow: hidden;
  display: block;
}

.main-sidebar {
  margin-left: 52px;
}

.function-item {
  margin-right: 18.84px;
  padding-right: 3.79px;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: #212121 !important;
  background: white url('../icons/check.svg') no-repeat 10px 10px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: #212121 !important;
  background: white url('../icons/info-blue.svg') no-repeat 10px 10px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #212121 !important;
  background: white url('../icons/error.svg') no-repeat 10px 10px !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #212121 !important;
  background: white url('../icons/delete-small.svg') no-repeat 10px 10px !important;
}

.Toastify__toast-body {
  padding: 8px 12px 8px 12px !important;
  padding-left: 40px !important;
  position: relative;
  display: block;
  height: inherit;
  margin: auto 0;
  flex: 1 1 auto;
  align-items: center;
  border-radius: 4px;
  white-space: pre-wrap;
}

.Toastify__toast {
  padding: 0 !important;
  font-family: unset !important;
  min-height: auto !important;
  box-shadow: 0 4px 6px 0 #10161A26;
}

.Toastify__toast-container--bottom-center {
  bottom: 0 !important;
  left: 45% !important;
  transform: unset !important;
  padding-top: 5px !important;
  margin-top: 5px !important;
}

.btn-group>.btn.active {
  z-index: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-footer {
  padding: 1rem !important;
  border-top: none !important;
}

.cursor-auto {
  cursor: auto;
}

.cursor-move {
  cursor: move;
}

.outline-none {
  outline: none;
}

.context-menu {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.btn-nav:hover {
  background: #DEE2E6 !important;
  border-radius: 4px;
  box-shadow: none;
}

.border-bottom-nav {
  border-bottom: 1px solid #DEE2E6;
}

.tab-border-right {
  border-right: 2px solid #DEE2E6;
}

.template-sidebar {
  flex: 0 0 230px;
  max-width: 230px;
  padding-inline: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.template-content {
  flex: 0 0 calc(100% - 230px);
  max-width: calc(100% - 230px);
  background-color: #fff;

  .input-group-text {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}


.template-item {
  border: 1px solid transparent;
}

.template-item:hover {
  background: #f8f9fa;
}

.template {
  border-radius: 4px;
  border-color: #014A80;
  background: #FFF !important;
}

.tab-content {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #014A80;
    border-radius: 18px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.navbar-steps {
  &::-webkit-scrollbar {
    height: 5px; // Changed from width to height for horizontal scrollbar
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #014A80;
    border-radius: 18px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.decision-design-content {
  max-width: 640px;
  margin: 0 auto;
}

.decision-design-title {

  h2 {
    color: #003057;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 13px;
    padding-top: 10px;
  }

  p {
    color: #6C757D;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
  }
}

.decision-design-btn {
  padding-inline: 21px;
  border-radius: 4px !important;
  background: #003057;
  font-size: 14px;
  line-height: 21px;
  color: white;
  width: 110px !important;
}

.decision-design-btn:hover {
  background: #003057;
}

.decision-design-btn:disabled {
  color: #D2D6DB;
  background: #F8F9FA;
  border: 1px solid #DEE2E6;
  width: 110px !important;
}


.textarea-length {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding-inline: 12px;
  padding-bottom: 8px;
  text-align: right;
  color: rgba(108, 117, 125, 0.66);
  line-height: 21px;
}

.decision-textarea textarea {
  min-height: 373px;
  padding: 8px 12px 35px;
  border-radius: 4px;
  filter: drop-shadow(0px 8px 15px rgba(0, 148, 229, 0.20)) drop-shadow(0px 1px 2px rgba(0, 48, 87, 0.37));
}

.decision-design-success-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h2 {
    color: #003057;
    font-size: 24px !important;
    line-height: 28px
  }

  p {
    color: #6C757D;
    font-size: 14px;
    line-height: 21px;
  }
}


.template-btn-bg {
  background: #f8f9fa !important;
  cursor: pointer;
  font-size: 14px;
}

.template-btn {
  border-radius: 4px !important;
  background: #0F3356 !important;
  color: white;
  font-size: 14px;
}


.template-btn:hover {
  color: white;
}

.dropdown-menu-position {
  top: 0;
  left: -1px;
  will-change: transform;
}

.any_value {
  display: flex;
  height: 39px;
  align-items: center;
  justify-content: center;
}

.table-layout-fixed {
  table-layout: fixed;
}

.w50 {
  width: 50px;
}

.w-70 {
  width: 70px;
}

.w-80 {
  width: 80px;
}

.min-h-100vh {
  min-height: 100vh;
}

.dashboard-chart-main {
  width: 100%;
  height: 300px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.dashboard-chart {
  position: relative;
  width: 682px;
  height: 300px;
  padding: 0;
  margin: 0;
  border-width: 0;
  cursor: default;
}

.z-index-100 {
  z-index: 100;
}

.input-item>div {
  display: flex;
  margin-right: -1px;
}

.mr3 {
  margin-right: 3px;
}

.spinner-grow {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.decision-table {
  margin-bottom: 20px;
  background: transparent !important;
  box-shadow: none !important;

  .table-bordered {
    th:not(.result) {
      border-bottom: 0 !important;
      border-right: 0 !important;
    }
  }

  .table {
    background: transparent;
    margin-bottom: 0;
    overflow-x: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

    .table-light td,
    .table-light th {
      padding-left: 20px;
    }

    thead {
      th {
        font-weight: bold;
      }

      th:first-child {
        width: 90px !important;
      }

    }
  }
}

.result-th {
  border-left: 3px solid #DEE2E6 !important;
}

.result-td {
  border-left: 3px solid #DEE2E6 !important;
}

table {
  margin-bottom: 0 !important;
  overflow-x: auto;
  font-family: 'Source Sans Pro', sans-serif;

  .table-light {

    td,
    th {
      padding: 0.8rem 1.4rem;
    }
  }

  thead {
    th {
      font-weight: 600;
      border-bottom: 2px solid #ddddddfa !important;
    }

    td {
      font-weight: 400;
      border-top: 0;
    }
  }


  th,
  td {
    vertical-align: middle !important;
  }
}

.login .modal-sm {
  max-width: 400px;
}

.__react_component_tooltip {
  z-index: 999999 !important;
  background: #191919 !important;
  margin: 0;
  opacity: 1 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  border-radius: 3px !important;
  display: inline-block !important;
  font-size: 13px !important;
  padding: 4px 8px !important;
  position: fixed !important;
  pointer-events: none !important;
  transition: opacity 0.3s ease-out !important;
}

.__react_component_tooltip::before {
  z-index: -20 !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
}

div.table-responsive {
  box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 0 0 rgb(16 22 26 / 0%), 0 1px 1px rgb(16 22 26 / 20%);
  background-color: #fff;
  border: 0;

  &.decision-table-settings {
    overflow-x: unset;
  }
}

.error-container {
  text-align: center;
  font-size: 180px;
  font-weight: 800;
  margin: 20px 15px;
}

.error-container>span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}

.error-container>span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.error-container>span:nth-of-type(1) {
  color: #D1F2A5;
  animation: colordancing 4s infinite;
}

.error-container>span:nth-of-type(3) {
  color: #F56991;
  animation: colordancing2 4s infinite;
}

.error-container>span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}

.error-container>span:nth-of-type(2):before,
.error-container>span:nth-of-type(2):after {
  border-radius: 0%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}

.error-container>span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }

  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 30px 0 rgba(209, 242, 165, 0.4),
      inset -30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }

  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 30px 0 rgba(245, 105, 145, 0.4),
      inset -30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }

  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
      inset 0 30px 0 rgba(255, 196, 140, 0.4),
      inset -30px 0 0 rgba(245, 105, 145, 0.4),
      inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }

  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
      inset 0 30px 0 rgba(239, 250, 180, 0.4),
      inset -30px 0 0 rgba(255, 196, 140, 0.4),
      inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}

@keyframes colordancing {
  0% {
    color: #D1F2A5;
  }

  25% {
    color: #F56991;
  }

  50% {
    color: #FFC48C;
  }

  75% {
    color: #EFFAB4;
  }

  100% {
    color: #D1F2A5;
  }
}

@keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }

  25% {
    color: #EFFAB4;
  }

  50% {
    color: #D1F2A5;
  }

  75% {
    color: #F56991;
  }

  100% {
    color: #FFC48C;
  }
}

.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}

.error-section {
  height: calc(100vh - 50px);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  padding-right: 0 !important;
}

.draggable-item {
  display: none !important;
  cursor: move;
  background: rgba(0, 192, 255, 0.4);
  z-index: 999;
  transition: all 0.5s ease;
}

.draggable-content {
  user-select: none;
  transition: all 0.5s ease;
  position: relative;
  opacity: 1;
}

.draggable-content:hover {
  opacity: 0.5;

  .draggable-item {
    position: absolute;
    display: block !important;
    ;
  }
}

.selectedRules {
  background: #faffff;
  border: 2px solid #0075ff66 !important;
}

.rule-selected {
  border: 1px solid #0075ff66 !important;
}

.border-2 {
  border-width: 2px !important;
}

.create-new-from-revision {
  height: 197px;
  line-height: 50px;
}

.pointer-events-none {
  pointer-events: none !important;
}

.right-0 {
  right: 0 !important;
  left: auto !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-1rem {
  font-size: 1rem !important;
}

.fce-dropdown-submenu {
  left: 100% !important;
  transform: unset !important;
  margin-left: 5px;
}

.height-200 {
  height: 200px;
}

.selectWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid #ced4da;
  border-right: 0;
  border-radius: 2px;
  background: #FFFFFF url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%3Cpath%20d%3D%22M4.33%208.5L0%201L8.66%201z%22%20fill%3D%22%2300AEA9%22%2F%3E%3C%2Fsvg%3E') right 13px center no-repeat;
}

.selectWrapper select {
  padding: 9px 40px 9px 20px;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: ellipsis;
}

@media (max-width: 1420px) {
  .selectWrapper select {
    padding: 9px 20px 9px 20px;
  }
}

.row-index .react-contextmenu-wrapper {
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rule-more-icon {
  transition: .2s ease;
}

.rule-more-icon:hover {
  background: #e1e1e1cf;
  border-radius: 10%;
  transition: .2s ease;
}

.grip-svg {
  position: absolute;
  left: 10%;
  top: 35%;
}

.word-break {
  word-break: break-word;
}

.function-content {
  .react-contextmenu-wrapper {
    display: contents;
  }

  .input-group-append {
    margin-left: 0;
  }
}

.context-menu-item {
  font-size: 14px;
  font-weight: 400;
}

nav nav.react-contextmenu {
  .react-contextmenu-item {
    &:hover {
      background-color: #cbcbcb33 !important;
    }
  }
}

.context-menu-item-link {
  color: #6C757D;
  font-size: 14px;
}

.context-menu-item-link:hover {
  color: #6C757D;
}

.function-select-menu {
  .react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item {
    color: #6C757D !important;
    font-size: 14px;
  }

  .react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item:after {
    color: #6C757D !important;
  }
}

.delete-item-menu {
  color: #F25835 !important;
  font-size: 14px;
  font-weight: 400;
  margin-top: 22px !important;
}

.btn-flow {
  background: #003057;
  border: 1px solid #003057;
  border-radius: 4px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #f8f9fa;
  display: flex;
  column-gap: 17px;
  align-items: center;
  width: 84px;
  height: 37px;
  justify-content: center;
}

.th-flow {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212121;
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.td-flow {
  cursor: pointer;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  vertical-align: none !important;
  height: 60px;
  line-height: 21px;
  color: #6c757d;
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;

  a {
    color: #6c757d;
  }
}

.modal-workflow__title {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #212121;
  margin-bottom: 0px;
}

.modal-workflow__title_color {
  color: #003057;
}


.w-1300 {
  max-width: 1300px !important;
}

.w-max-content {
  width: max-content !important;
}

.workflow-modal {
  max-width: 700px;

  .modal-content {
    border: none;
    padding: 18px 30px 29px 30px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25),
      0px 1px 1px rgba(16, 22, 26, 0.2);
    border-radius: 8px;

    .modal-footer {
      padding: 0px !important;
      border-top: none;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  div {
    padding: 0px;
  }
}


.template-modal {
  .modal-content {
    height: 764px;
    max-height: 764px;
  }

  [class*="wrapper"] {
    height: 100%;

    .modal-body {
      min-height: 100%;
      height: 100%;

      .modal-container {
        height: calc(100% - 66px);
      }
    }
  }
}

.strategy-data {
  h2 {
    color: #455A64;
    font-size: 24px;
    line-height: 28px;
  }
}

.strategy-date {
  color: #6C757D;
  font-size: 14px;
  line-height: 21px;
}

.read-text-btn {
  padding: 8px 12px;
  color: #6C757D;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid #DEE2E6;
  background: #F8F9FA;
  border-radius: 4px !important;
}

.modal-header-title {
  color: #003057;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.custom-scrollable-modal-body {
  .modal-content {
    padding: 15px 30px;
    max-height: 686px;

    .modal-body {
      max-height: calc(100% - 152px);
      overflow-y: auto;
    }

    .modal-footer {
      padding-inline: 0 !important;
    }

    .modal-header {
      padding-right: 0 !important;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #014A80;
    border-radius: 18px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


.template-item-decision {
  display: flex;
  justify-content: space-between;
  padding: 8px 27px;
  background: white;
  color: #6C757D;
  border: 1px solid #DEE2E6;
}

.artifact-group {
  display: flex;
  flex-wrap: wrap;

  .artifact-group-item {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
    padding-right: 24px;
    margin-bottom: 20px;

    @media (max-width: 1100px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 24px;
      margin-bottom: 20px;
    }

    @media (max-width: 860px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 24px;
      margin-bottom: 20px;
    }
  }

  h2 {
    color: #6C757D;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}


.menu-item-btn {
  padding-block: 8px;
}

.workflow-modal-input {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6C757D;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 8px 0px 8px 12px;
  max-width: 312px;
  width: 100%;
}

.w-400 {
  max-width: 400px !important;
}

.add-option {
  border-width: 1px 1px 1px 1px;
  border-color: #DEE2E6;
  border-radius: 0px 4px 4px 0px;
  background: #F8F9FA;
  font-size: 14px;
  padding: 0 14.4px;
  color: #6C757D;
}

.add-option:hover {
  background: #dee2e6;
  color: #6C757D;
}

.data-sources {
  border: 1px solid #DEE2E6 !important;
  font-size: 14px;
}

.workflows-modal-btn {
  transition: .2s ease-in-out;
  background: #F8F9FA;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6C757D;
  display: flex;
  align-items: center;
  padding: 7px 12px !important;
  cursor: pointer;
  margin: 0px;
  column-gap: 15px;
}

.workflows-modal-btn:hover {
  transition: .2s ease-in-out;
  background: #dee2e6;
  color: #6C757D;
}

.save-step {
  transition: .2s ease-in-out;
  background: #0F3356;
}

.save-step:hover {
  transition: .2s ease-in-out;
  background: #014A80;
}

.text-gray {
  color: #6C757D;
}

.container-option {
  .option-step {
    border-bottom: 1px solid #DEE2E6;
    padding: 12px 0 !important;

    &.option-opened {
      border-bottom: 0;
      padding-bottom: 8px !important;

      &~[id*=option-content] {
        border-radius: 4px;
      }
    }
  }

  &:first-child {
    .option-step {
      border-top: 1px solid #DEE2E6;
    }
  }
}

.option-delete:hover {
  background: #DEE2E6;
}

.option-delete:hover svg {
  color: #dc3545 !important;
}

.btn-table {
  margin-bottom: auto !important;
  margin-top: auto !important;
  top: 0 !important;
  bottom: 0 !important;
  position: absolute !important;
  height: 18px !important;
  margin-right: 20px !important;
}

.btn-row {
  position: relative !important;
  margin-right: 15px;
}

.bg-gray {
  background: #F8F9FA;
}

.option-select {
  border-radius: 4px 0 0 4px !important;
  border-color: #dee2e6;
  font-size: 14px;
}

.option-select:hover {
  color: #6C757D;
}

#option-details .react-autosuggest__input {
  border-left: 0 !important;
  border-radius: 0 4px 4px 0 !important;
}

.subflow-option .react-autosuggest__input {
  color: #6C757D;
  border: 1px solid #dee2e6;
  border-radius: 0 4px 4px 0 !important;
}

#context-table-container {
  .input-group {
    flex-wrap: initial !important;
  }
}

.mb-29 {
  margin-bottom: 29px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.primary {
  display: inline-block;
  font-size: 14px;
  transition: .2s ease-in-out;
  border-radius: 4px !important;
  background: #0F3356 !important;
  color: white;
}

.border-radius-left-4 {
  border-radius: 4px 0 0 4px !important;
}

.border-radius-right-4 {
  border-radius: 0 4px 4px 0 !important;
}

.deploy-btn {
  width: 110px;
  height: 40px;
}

.primary:hover {
  transition: .2s ease-in-out;
  background: #014A80 !important;
  color: white !important;
  ;
}

.primary-header {
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: .2s ease-in-out;
  border-radius: 4px !important;
  background: #0F3356 !important;
  color: white;
}

.primary-header:hover {
  transition: .2s ease-in-out;
  background: #014A80 !important;
  color: white !important;
  ;
}


.outline {
  display: inline-block;
  border: 1px solid #DEE2E6;
  font-size: 14px;
  transition: .2s ease-in-out;
  border-radius: 4px !important;
  background: #F8F9FA !important;
  color: #6C757D;
}

.border-radius-none-right-top-bottom {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-radius-none-left-top-bottom {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rule-switch {
  margin-top: 6px;
  margin-right: 8px;
}

.outline.active {
  background: #003057 !important;
  color: white;
}

.outline.active:hover {
  background: #014A80 !important;
  color: white;
}

.outline:hover {
  transition: .2s ease-in-out;
  background: #F8F9FA !important;
}


.outline-header {
  display: flex;
  align-items: center;
  border: 1px solid #DEE2E6;
  font-size: 14px;
  transition: .2s ease-in-out;
  border-radius: 4px !important;
  background: #F8F9FA !important;
  color: #6C757D;
}


.outline-header.active {
  background: #003057 !important;
  color: white;
}

.outline-header.active:hover {
  background: #014A80 !important;
  color: white;
}

.outline-header:hover {
  transition: .2s ease-in-out;
  background: #DEE2E6 !important;
}

[type="checkbox"] {
  accent-color: #0F3356 !important;
}

[type="checkbox"]:hover {
  accent-color: #014A80 !important;
}

.custom-control-input:hover~.custom-control-label::before {
  border-color: #003057 !important;
  box-shadow: #003057 !important;
}

.custom-control-input:checked:hover~.custom-control-label::before {
  background-color: #014A80 !important;
  border-color: #014A80 !important;
  box-shadow: #014A80 !important;
}

.custom-control-input:focus~.custom-control-label::before {
  border-color: #003057 !important;
  box-shadow: #003057 !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #003057 !important;
  background-color: #003057 !important;
}

.custom-control-input:active~.custom-control-label::before {
  background-color: #003057 !important;
  border-color: #003057 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #003057 !important;
}

.custom-control-input-green:not(:disabled):active~.custom-control-label::before {
  background-color: #003057 !important;
  border-color: #003057 !important;
}

.primary-text {
  color: #003057;

  :hover {
    color: #014A80;
  }
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #014A80 !important;
}

.btn:focus,
.form-control:focus {
  border-color: #014a8030 !important;
  outline: 0;
  box-shadow: 0.2px 0.2px 0.3rem 1.2px #014a8030 !important;
}

.new-rule-btn:focus {
  border: none !important;
  box-shadow: none !important;
}

.border-info {
  border-color: #0F3356 !important;
}

.text-info {
  color: #014A80 !important;
}

.add-row-bg {
  background: #F8F9FA;
}

.thead-border {
  border-bottom: 2px solid #DEE2E6;
}

.shadow {
  box-shadow: 0 4px 6px 0 #10161A26;
}

.transition {
  -webkit-animation: 1s ease 0s normal forwards 1 fadein;
  animation: 1s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hidden {
  -webkit-animation: .5s ease 0s normal forwards 1 fadeOut;
  animation: .5s ease 0s normal forwards 1 fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
}

select {
  appearance: none;
  background: url('../icons/arrow-down.svg') no-repeat right;
  background-position: calc(100% - 0.75rem) center !important;
}

.w-80-5 {
  width: 80.5%;
}

.connected {
  height: 15px;
  width: 14px;
  background: url('../icons/connected.svg') no-repeat;
}

.connected-btn:hover {
  color: #6C757D;

  .connected {
    background: url('../icons/disconnected.svg') no-repeat;
  }
}

table {
  td {
    .dropdown-item {
      display: flex !important;
      align-items: center !important;

      svg {
        margin-right: 5px;
      }
    }
  }
}

button.dropdown-item,
.dropdown-centered {
  display: flex !important;
  align-items: center !important;

  svg {
    margin-right: 5px;
  }
}

.w-150 {
  width: 150px;
}

.pr-35 {
  padding-right: 35px;
}

.w-90 {
  width: 90px;
}

.w-95 {
  width: 95px;
}

.math-symbols {
  background-color: #DEE2E6 !important;
  width: 120px;
}

.ruleset-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 120px;
  position: relative;

  .large-item {
    flex: 0 0 calc(50% - 50px);
    max-width: calc(50% - 100px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 90px;
    width: 20px !important;
    height: 1px;
    background-color: #dee2e6;
  }

  &:not(.group-rule-set-row) {
    &:first-child {
      &:before {
        width: 50px;
      }
    }
  }
}

.cond-group-btn {
  margin-right: 18px;
  font-size: 16px;
}

.password-eye {
  height: 43px;
}

.signup-checkbox {
  text-align: left;
  font-size: 14px;
}

.condition-group {
  padding-left: 110px;
}

.condition-container {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 90px;
    width: 1px;
    height: calc(100% - 18px);
    background-color: #dee2e6;
  }

  &.condition-container-group {
    &:before {
      left: 200px;
      height: calc(100% - 23px);
    }
  }
}

.section-divider {
  padding-top: 15px;
  border-top: 1px solid #dee2e6;
}

.new-rule {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  margin-bottom: 15px !important;
}

.condition-label {
  width: 50px;
  padding-top: 8px !important;
}

.condition-label-group {
  width: 50px;
  max-height: 20px !important;

  &~button {
    max-height: 38px !important;
  }
}

.text-gray-66 {
  color: #6C757DA8;
}

.method,
.oauth2-tokens .select__control {
  border-radius: 4px 0 0 4px !important;
}

.oauth2-tokens {
  width: 70%;
}

.h-fit-content {
  height: fit-content;
}

.add-oauth2-token {
  height: 38px;
}

.add-token-form {
  input {
    margin-bottom: 10px;
  }

  label {
    margin-bottom: 0;
  }
}

.data-source-table {

  th,
  td {
    color: #6C757D;
    padding: 8px;
    border-right: 1px solid #dee2e6;
  }

  tbody {
    td {
      padding: 4px 10px;
    }
  }

  input {
    color: #6C757D;
    border-bottom: 0;
  }

  tfoot {
    th {
      font-weight: 400;
    }
  }
}

.table-pretty {
  tr {
    &:first-child {
      th {
        border-top: 0 !important;

        &:last-child {
          border-right: 0 !important;
        }
      }

      td {
        border-top: 0 !important;
      }
    }
  }

  th,
  td {
    vertical-align: top;
    color: #6C757D;
    padding: 8px;
    border-right: 1px solid #dee2e6;
  }

  tbody {
    td {
      padding: 7px 10px;
    }

    tr {
      &:hover {
        background: #F8F9FA;
      }
    }
  }

  input {
    color: #6C757D;
    border-bottom: 0;
  }

  tfoot {
    th {
      font-weight: 400;
    }
  }
}

.login-modal {
  font-style: normal;
  width: 100%;
  max-width: 384px;
  margin: 0 auto;

  &__title {
    font-family: Nunito Sans;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #212121;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }

  &__btn {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background: #003057;
    border: 1px solid #003057;
    border-radius: 4px;
    padding: 8px 36px;
    color: #F8F9FA;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  &__agree {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: start;

    input {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  :focus {
    z-index: 2;
  }

}

.vertical-align-middle {

  th,
  td {
    vertical-align: middle !important;
  }
}

.vertical-align-top {

  th,
  td {
    vertical-align: top !important;
  }
}

.line-height-21 {
  line-height: 21px;
}

#templates-tab {
  .nav-link.active {
    color: white;
    background: #0F3356 !important;
  }
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.fw-600 {
  font-weight: 600 !important;
}

.delete-column {
  width: 50px;
}

.decision-designer-table {
  thead {
    th {
      color: #212121;
      line-height: 21px;
      font-size: 14px;

      &:first-child {
        width: 400px;
      }

      &:last-child {
        width: 267px;
      }
    }
  }
}

.strategies-btn {
  padding: 4px 8px;
  border-radius: 4px !important;
  border: 1px solid #003057 !important;
  background: #FFF !important;
  color: #003057 !important;
}

.strategies-btn:focus {
  box-shadow: unset !important;
  border-color: #003057 !important;
}


.strategies-col {
  color: #6C757D;
  font-size: 14px;
  line-height: 21px;
}

.language-dropdown {
  button {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.flags {
  width: 60px;
  height: 30px;
  margin-bottom: 15px;
}

.mr12 {
  margin-right: 12px !important;
}

.identifier-icon {
  color: #fff;
  background-color: #6c757d;
  padding: 2px;
  border-radius: 999px;
  margin-left: 5px;
}

.attribute-header {
  border: 1px solid #DEE2E6;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;

  .right-border {
    padding: 8px 12px !important;
    border-right: 1px solid #DEE2E6;

    &:last-child {
      border-right: 0;
    }
  }
}

.column-delete {
  flex: 0 0 40px;
  max-width: 41px;
}

.attribute-container {
  padding: 32px 12px;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
  background: #F9F9F9;

  input,
  select {
    border-radius: 4px 0 0 4px;
    border: 1px solid #DEE2E6;
    background: #FFF;
    margin-right: 6px;
  }

  .input-group-text {
    margin-right: 5px;
  }
}

.table-title {
  color: #6C757D;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 32px;
  margin-bottom: 8px;
}

.add-btn {
  border-radius: 4px !important;
  border: 1px solid #DEE2E6;
  background: #F8F9FA;
  margin-left: 1px;
}

.function-icon {
  max-width: 67px;
  flex: 0 0 67px;
}

.w-42 {
  width: 41px;
  background: #F8F9FA !important;
  border-radius: 0 !important;
  border-bottom-right-radius: 4px !important;
}

.function-delete-icon {
  border-radius: 0 !important;
  background: white !important;
  border-top: none !important;

  &:hover {
    background: white !important;
  }
}

.attribute-name-input {
  border-top: none !important;
  border-radius: 0 !important;
}

.attribute-row {
  &:last-child {
    .attribute-name-input {
      border-bottom-left-radius: 4px !important;
    }
  }
}

.attribute-name-input:focus {
  box-shadow: none !important;
}

.react-autosuggest__input:focus-visible {
  outline: unset !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.css-a0lnwq-singleValue {
  margin-left: 0 !important;
  margin-top: 10px !important;
}

.react-contextmenu {
  &>div {
    border-bottom: 1px solid #DEE2E6;

    &:last-child {
      border: none;
    }

    .react-contextmenu-item--selected,
    .react-contextmenu-item--active {
      background-color: unset !important;
      box-shadow: none !important;
    }

    &:hover {
      background-color: #cbcbcb33;
    }
  }
}

.convert-json {
  background-color: #f5f5f5;
  width: 100%;
}

.ellipsis-text {
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
}

.decision-table-column {
  span {
    margin: 0 !important;
  }
}

.min-column-width-200 {
  min-width: 150px;
  width: auto;
}

.decision-table {
  .td {
    min-width: 2000px;
    width: auto;
  }

}

.scorecard-bin {
  .input-group-text {
    padding: .45rem 0.75rem;
  }
}

// AIDD
.aidd.sidebar-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  /* Full viewport height */
  width: 300px;
  /* Or any other width based on your design */
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.aidd {
  .message-list {
    flex: 1;
    overflow-y: auto;
    margin: 0;
    padding: 10px;
    list-style: none;
    padding-top: 50px;
  }

  .message-item {
    padding: 5px 10px;
    margin-bottom: 8px;
    border-radius: 20px;
    background-color: #f1f1f1;
  }

  .message-item.user {
    text-align: right;
    background-color: #007bff;
    color: white;
  }

  .message-item.bot {
    text-align: left;
    background-color: #e0e0e0;
  }

  .chat-input {
    padding: 10px;
    background-color: white;
  }

  .chat-input input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
  }

  .chat-input button {
    padding: 10px 20px;
  }
}

//Data object attribute table
#data-object-details {
  .attribute-row {
    border-bottom: 1px solid #dee2e6;
  }

  .attribute-row:hover {
    background-color: #f1f1f1;
  }

  .attribute-row input,
  .attribute-row input:disabled {
    border: none;
    background-color: transparent;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}