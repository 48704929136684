/* Scrollbar styling */
.chat-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.chat-container::-webkit-scrollbar {
  width: 6px;
}

.chat-container::-webkit-scrollbar-track {
  background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/* Message container */
.message {
  max-width: 80%;
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  gap: 4px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #003057; /* Use your theme color */
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

/* Message content adjustments */
.form-control {
  transition: height 0.1s ease-out;
}

/* Update user message styling */
.user-message .message-content {
  border-radius: 1rem 1rem 0.25rem 1rem !important;
  background-color: #0f3356 !important; /* Dark blue from your theme */
  color: white;
}

/* Update AI message styling */
.ai-message .message-content {
  border-radius: 1rem 1rem 1rem 0.25rem !important;
  background-color: #f8f9fa !important; /* Light gray from your theme */
  border: 1px solid #dee2e6; /* Light border for better definition */
}

/* Add hover effect to add rule button inside AI messages */
.ai-message button.outline:hover {
  background: #dee2e6 !important;
}

/* Improve send button styling */
.btn.outline {
  border: 1px solid #dee2e6 !important;
  color: #455a64;
}

.btn.outline:hover {
  background-color: #dee2e6 !important;
}

/* Modal header styling */
.modal-header h5.modal-title {
  color: #455a64;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
}

/* Text area styling */
.form-control.rounded-pill {
  border-radius: 1rem !important;
}

.form-control.rounded-pill::-webkit-scrollbar {
  width: 6px;
}

.form-control.rounded-pill::-webkit-scrollbar-track {
  background: transparent;
}

.form-control.rounded-pill::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
