.batch-test-container,
.batch-test-dropdown {
  /* Base container styles */
  .batch-test-container,
  .batch-test-progress,
  .batch-test-results {
    margin-bottom: 20px;
    border-radius: 4px;
  }

  /* Common headings */
  .batch-test-container h4,
  .batch-test-results h4,
  .batch-test-progress h4 {
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #495057;
  }

  /* Results headings should be slightly larger */
  .batch-test-results h4 {
    font-size: 1.05rem;
    font-weight: 600;
  }

  /* Progress specific styles */
  .batch-test-progress {
    background: #f8f9fa;
    padding: 15px;
    border: 1px solid #dee2e6;
  }

  /* Card-based layout for input methods */
  .batch-input-methods {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .batch-input-methods {
      flex-direction: column;
    }
  }

  /* Input method cards */
  .input-method-card {
    flex: 1;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background: #fff;
  }

  .input-method-card:hover {
    box-shadow: 0 2px 6px rgba(0, 48, 87, 0.08);
    transform: translateY(-1px);
  }

  .input-card-header {
    background: #f8f9fa;
    padding: 10px 12px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
    position: relative;
  }

  .input-card-header svg {
    width: 16px;
    height: 16px;
    color: #6c757d;
  }

  .input-card-header h5 {
    margin: 0;
    font-weight: 500;
    font-size: 0.95rem;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .input-card-body {
    padding: 12px;
  }

  .input-card-description {
    color: #6c757d;
    font-size: 0.85rem;
    margin-bottom: 12px;
    line-height: 1.4;
  }

  /* Dataset selection dropdown */
  .dataset-dropdown {
    position: relative;
    margin-bottom: 10px;
  }

  .dataset-list {
    position: fixed;
    z-index: 9999;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.15s ease-in;
    min-width: 200px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-5px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dataset-list-header {
    position: sticky;
    top: 0;
    padding: 7px 10px;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    z-index: 1;
  }

  .btn-close {
    background: transparent;
    border: none;
    padding: 2px 6px;
    font-size: 1.2rem;
    line-height: 0.5;
    color: #6c757d;
    cursor: pointer;
  }

  .btn-close:hover {
    color: #333;
  }

  .dataset-items-container {
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dataset-item {
    padding: 7px 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background 0.1s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85rem;
  }

  .dataset-item:last-child {
    border-bottom: none;
  }

  .dataset-item:hover {
    background: #f5f5f5;
  }

  /* Dataset dropdown footer */
  .dataset-list-footer {
    border-top: 1px solid #dee2e6;
    padding: 8px 10px;
    text-align: center;
    background: #f8f9fa;
  }

  /* Dataset creator */
  .dataset-creator {
    background: #f8f9fa;
    padding: 12px 15px;
    border-radius: 4px;
    border: 1px solid #dee2e6;
  }

  .dataset-creator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .dataset-creator-header h5 {
    margin: 0;
    font-weight: 500;
    font-size: 0.95rem;
  }

  /* Dataset manager styles */
  .dataset-manager {
    margin-bottom: 20px;
  }

  /* Virtual table container (Batch Test Results) */
  .virtual-table-container {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    overflow: hidden;
    width: 100%; /* Ensure container takes full width */
  }

  .table-header {
    display: flex;
    background: #f8f9fa;
    padding: 8px 0;
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
    align-items: center;
    width: 100%; /* Ensure header takes full width */
  }

  .virtual-row {
    display: flex;
    width: 100%; /* Ensure rows take full width */
    padding: 8px 0;
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    align-items: center;
  }

  .result-pre {
    margin: 0;
    max-height: 40px;
    overflow: hidden;
    font-size: 0.7rem;
    line-height: normal;
    width: 100%;
  }

  .table-col {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 8px;
    font-size: small;
  }

  .col-number {
    width: 5%;
    min-width: 50px;
  }

  .col-status {
    width: 10%;
    min-width: 80px;
  }

  .col-content {
    width: 37.5%; /* Increased from 34% to fill space */
    min-width: 200px;
  }

  .col-actions {
    text-align: center;
    align-items: center;
    display: flex;
    width: 10%;
    min-width: 70px;
  }
  .col-actions .w-100 {
    text-align: center;
    width: 100%;
  }

  /* Loading indicator */
  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #6c757d;
  }

  /* Form controls with compact sizing */
  .form-control {
    font-size: 0.85rem;
    padding: 0.35rem 0.5rem;
    border-radius: 3px;
  }

  .form-text {
    font-size: 0.75rem;
  }

  /* Remove excess space between form elements */
  .form-group + .form-group {
    margin-top: 0.75rem;
  }

  /* Spacing for labels */
  label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: 0.85rem;
  }

  /* Make sure text in the dataset form aligns with the rest of the UI */
  .card {
    border-radius: 4px;
  }

  .text-muted {
    color: #6c757d !important;
    font-size: 0.8rem;
  }

  /* Make sure buttons are aligned properly */
  .w-100 {
    width: 100%;
  }

  /* Make sure buttons in forms have proper spacing */
  form button {
    margin-top: 8px;
  }

  /* Progress bar styling */
  .batch-test-progress {
    background: #f8f9fa;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #dee2e6;
    margin-bottom: 20px;
  }

  /* Make the heading in batch progress less prominent */
  .batch-test-progress h4 {
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: #495057;
    padding-left: 8px;
  }

  .progress {
    height: 0.7rem;
    border-radius: 3px;
    background-color: #e9ecef;
    overflow: hidden;
    margin-top: 10px;
  }

  .progress-bar {
    height: 100%;
    color: white;
    background-color: #003057;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
  }

  /* Ensure VirtualRow components are positioned correctly */
  .react-window-list {
    overflow: auto;
  }

  .batch-test-results {
    margin-top: 25px !important;
  }

  .header-action-btn {
    margin-left: auto;
  }

  .btn-sm {
    padding: 0.15rem;
    font-size: 0.75rem;
  }
}
