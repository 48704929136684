.impact-analyzer-overlay {
  position: fixed;
  top: 40px;
  /* Match your header height */
  right: 0;
  bottom: 0;
  z-index: 1050;
}

.impact-analyzer-results {

  /* Virtual table container */
  .virtual-table-container {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .table-header {
    display: flex;
    background: #f8f9fa;
    padding: 8px;
    font-weight: bold;
    border-bottom: 2px solid #dee2e6;
    align-items: center;
    width: 100%;
  }

  .virtual-row {
    display: flex;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    transition: background-color 0.1s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .result-pre {
    margin: 0;
    max-height: 40px;
    overflow: hidden;
    font-size: 0.75rem;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
  }

  .table-col {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 8px;
    font-size: small;
  }

  .col-number {
    width: 5%;
    min-width: 50px;
    justify-content: center;
    font-weight: 500;
    color: #6c757d;
  }

  .col-content {
    width: 28%;
    min-width: 200px;
    cursor: pointer;

    &:hover .result-pre {
      color: #0066cc;
    }
  }

  .col-status {
    width: 11%;
    min-width: 100px;
    justify-content: center;
    text-align: center;
  }

  /* Badge styling */
  .badge {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0.35em 0.65em;
  }

  /* Loading indicator */
  .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #6c757d;
  }
}