.rule-compared-box-shadow {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
}

.rule-compared-box-shadow .card-header {
  background-color: #f7f7f7;
  padding: 12px 16px;
}

/* Highlight changed fields */
.changed-field {
  border: 2px solid #ff9800; /* Orange border */
  background-color: #fff7e6;
}
