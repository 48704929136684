.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  gap: 20px;
  width: 100%;
  height: 37px;
  font-family: 'Nunito Sans', sans-serif;
}

.pagination-info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #6C757D;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.pagination button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 40px;
  height: 37px;
  background: #F8F9FA;
  border: 1px solid #DEE2E6;
  margin: 0px -1px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6C757D;
}

.pagination button:first-child {
  border-radius: 4px 0px 0px 4px;
}

.pagination button:last-child {
  border-radius: 0px 4px 4px 0px;
}

.pagination button.active {
  background: #DEE2E6;
}

.pagination button:hover:not([disabled]) {
  background-color: #e9ecef;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: default;
  opacity: 0.65;
}