.react-contextmenu {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  pointer-events: none;
  text-align: left;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.operators-context-menu {
  position: fixed !important;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 21px;
  text-align: inherit;
  white-space: nowrap;
  padding: 7px 0 7px 12px;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  background-color: #cbcbcb33;
  text-decoration: none;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-submenu:hover > .react-contextmenu {
  opacity: 1;
}

.react-contextmenu-submenu > .react-contextmenu {
  max-height: 200px;
  overflow: auto;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  padding: 0 47px 0 0;
  color: #077fff;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▷";
  color: #077fff;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  right: 12px;
}

input {
  zoom: 1;
}
