/*
 * Sidebar
 */
/*
 * Content
 */
/*
 * Navbar
 */
/** autocomplete */
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.dashboard-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.dashboard-card {
  width: 460px;
  height: 95px;
  background: #F8F9FA;
  border-radius: 12px;
  padding: 12px 31px 5px 21px;

  &__row {
    // height: 55px;
    display: flex;
    // align-items: start;
    justify-content: space-between;
    margin-top: 20px;
    height: auto;
  }

  &__desc {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 21px;
    color: #003057;
  }

  h2 {
    font-family: Poppins !important;
    ;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 21px;
    color: #003057;
    text-transform: uppercase;
  }

  &__subtitle {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #003057;
  }
}

.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    .feather {
      margin-right: 4px;
      color: #999;
    }

    &:hover {
      .feather {
        color: inherit;
      }
    }
  }

  .nav-link.active {
    color: #007bff;

    .feather {
      color: inherit;
    }
  }

  .position-absolute.fixed-bottom {
    z-index: 100;
  }
}

.copy-modal {
  max-height: 570px;
  overflow-y: auto;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar {
  .nav-link {
    font-weight: 500;
    color: #333;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.menu-link-element {
  z-index: 150;
}

#sidebar-mover {
  z-index: 230;
}

[role="main"] {
  padding-top: 60px;
}

.white-space-pre {
  white-space: pre;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar {
  .outline {
    background: transparent !important;
  }

  .outline:hover {
    background: #DEE2E6 !important;
  }

  .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.decision-table {
  tr.highlight {
    border: 1px solid black;
  }

  td {
    margin: 0 !important;
    padding: 0 !important;
    vertical-align: middle;
    cursor: auto;

    &:first-of-type {
      text-align: center;

      div {
        width: 90px;
        min-width: max-content;
      }
    }

    span,
    input {
      height: 45px;
    }
  }

  input {
    border: none;

    &:focus {
      border: 0.5px solid black;
    }
  }

  .input-group-prepend {
    .input-group-text {
      border: none;
      border-radius: 0;
    }
  }

  .input-group-append {
    .input-group-text {
      border: none;
      border-radius: 0;
    }
  }
}

.rule-set-btn {
  position: relative;
  width: 120px !important;
  text-align: left;
  color: darkgrey;
  height: 38px;

  &:after {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
  }

  .icon {
    display: none;
  }
}

.data-attribute-btn {
  position: relative;
  width: 150px !important;
  text-align: left;
  color: darkgrey;

  &:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon {
    display: none;
  }
}

.rule-set-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 !important;
  border-bottom: 0 !important;
}

.first-row {
  .rule-set-header {
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }
}

.last-row {
  .rule-set-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
}

.new-rule-btn {
  position: relative;
  color: gray;
  box-shadow: none !important;
  border: none !important;
  padding-left: 22px !important;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #DEE2E6;
    left: 2px;
    top: 0;
  }
}


.add-rule-container {
  display: flex;
  justify-content: center;
  background: #F8F9FA;
  padding-block: 16px;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.container-border {
  border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.card-body-content {
  border-radius: 4px;
  padding: 12px 16px;
  background: #F8F9FA;
}

.card-border {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0 !important;
}

.rule-box-shadow {
  box-shadow: none !important;
}

.w-170 {
  width: 170px;
}

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100000000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  >li {
    >div {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333333;
      white-space: nowrap;
    }
  }
}

.ui-state-hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
  cursor: pointer;
}

.ui-state-active {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
  cursor: pointer;
}

.ui-state-focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
  cursor: pointer;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.table-dropdown {
  left: -30px !important;
}

.small-table-dropdown {
  left: -20px !important;
}

.dropdown-menu {
  a {
    cursor: pointer;
  }

  :hover {
    background-color: unset !important;
  }

  .dropdown-item:hover {
    background-color: #f5f5f5 !important;
  }

  li.dropdown-item {
    a {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#canvas {
  position: absolute;
  width: calc(100% - 36px);
  height: 500px;
  background-repeat: repeat;
  background-size: 30px 30px;
  background-color: #fbfbfb;
  z-index: 0;
  overflow-y: auto;
  overflow-x: auto;
}

.condition-operation {
  height: 38px;
  background-color: #fbfbfb;
}

.h-38 {
  height: 38px !important;
}

.form-control-plaintext {
  border-bottom: 0.5px solid #ced4da;
}

button {
  border-radius: 0;
}

.form-control {
  border-radius: 0;
}

.btn {
  border-radius: 0;
}

.custom-file-input {
  border-radius: 0;
}

.custom-file-label {
  border-radius: 0;
}

th.function-col {
  width: 150px;
}

.input-group-append {
  .input-group-text {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 5px;
  }
}

.tab-pane {
  .input-group-append {
    .input-group-text {
      border-radius: 0.25rem;
    }
  }
}

.form-control-sm {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend {
  .input-group-text {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: 7px;
  }
}

.formula-draggable-section {

  input:disabled,
  select:disabled {
    background: white;
    color: black;
    opacity: 1;
  }
}

.sort-handle {
  cursor: move;
  width: 30px;
}

.accordion {
  >.card {
    &:first-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
    }
  }
}

.flowchart-container {
  height: 100%;
}

.tooltip {
  pointer-events: none;
}

.input-styles {
  border-radius: 4px 0 0 4px;
  border: 1px solid #DEE2E6;
  background: #FFF;
  margin-right: 5px;
  min-width: 198px;
  height: 38px;
  display: flex;
  align-items: center;
}

.data-object-table {
  overflow-wrap: anywhere;
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px;
  }

  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

@media (max-width: 991px) {
  .border-md {
    border: 2px solid #d5d5d540;
  }

  .overflow-md-auto {
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .border-sm {
    border: 2px solid #d5d5d540;
  }

  .overflow-sm-auto {
    overflow: auto;
  }

  .h-sm-25 {
    height: 25%;
  }
}

@media (max-width: 1100px) {
  .rule-set-btn {
    width: 50px !important;
    padding-left: 5px;
    padding-right: 15px;

    // Center the icon
    text-align: center;

    // Hide the text completely
    span:not(.icon) {
      display: none;
    }

    // Make sure icon is visible and centered
    .icon {
      display: inline-block;
      margin: 0 auto;
      position: relative;
      left: 0;
    }
  }

  .data-attribute-btn {

    // Hide the text completely
    span:not(.icon) {
      display: none;
    }

    // Make sure icon is visible and centered
    .icon {
      display: inline-block;
      margin: 0 auto;
      position: relative;
      left: 0;
    }
  }
}

// Add this new selector to target .ai-message containers regardless of screen size
.ai-message {
  .rule-set-btn {
    width: 50px !important;
    padding-left: 5px;
    padding-right: 15px;

    // Center the icon
    text-align: center;

    // Hide the text completely
    span:not(.icon) {
      display: none;
    }

    // Make sure icon is visible and centered
    .icon {
      display: inline-block;
      margin: 0 auto;
      position: relative;
      left: 0;
    }
  }

  .data-attribute-btn {

    // Hide the text completely
    span:not(.icon) {
      display: none;
    }

    // Make sure icon is visible and centered
    .icon {
      display: inline-block;
      margin: 0 auto;
      position: relative;
      left: 0;
    }
  }
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none !important;
}